import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import MicroMagYbtj from "../images/microMagybtj.jpg"
import MicroHDYbtj from "../images/FC-MICRO-HD-YBTJ-1.jpg"
import MiniHDYbtj from "../images/FC-MINI-HD-YBTJ-1.jpg"
import MagHDYbtj from "../images/FC-MAG-HD-YBTJ-1.jpg"
import Sweeper34Ybtj from "../images/tn_34ubthejudgefc.jpg"
import TrYbtj from "../images/tn_TRubthejudgefc.jpg"
import XrYbtj from "../images/tn_xrubthejudgefc.jpg"
import GtxYbtj from "../images/tn_gtxubthejudgefc.jpg"

const PageLayout = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
`
const DocumentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: space-around;

  img {
    width: 200px;
    margin: 1rem;
    border: 1px solid black;
  }

  h4 {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: black;
  }
`

const YouBeTheJudgePage = () => {
  return (
    <Layout>
      <PageLayout>
        <h1>You Be the Judge</h1>
        <h4>Download Documents</h4>
        <DocumentContainer>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/MICROMAG-YBTJ-FC.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MicroMagYbtj} alt="img"/>
            <h4>MicroMag</h4>
          </a>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/FC-MICRO-HD-YBTJ.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MicroHDYbtj} alt="img" />
            <h4>Micro-HD</h4>
          </a>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/FC-MINI-HD-YBTJ.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MiniHDYbtj} alt="img"/>
            <h4>Mini-HD</h4>
          </a>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/FC-MAG-HD-YBTJ.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MagHDYbtj} alt="img" />
            <h4>Mag-HD</h4>
          </a>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/34-YBTJ-FC.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Sweeper34Ybtj} alt="img" />
            <h4>34</h4>
          </a>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/TR-YBTJ-FC.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TrYbtj} alt="img" />
            <h4>TR</h4>
          </a>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/XR-YBTJ-FC.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={XrYbtj} alt="img" />
            <h4>XR v2.0</h4>
          </a>
          <a
            href="https://www.factorycat.com/docs/youBeTheJudge/GTX-YBTJ-FC.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GtxYbtj} alt="img" />
            <h4>GTX</h4>
          </a>
        </DocumentContainer>
      </PageLayout>
    </Layout>
  )
}

export default YouBeTheJudgePage
